<template>


  <div v-if="!is_load">

    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
       
        <router-link to="/admin/vehicle/lists"> Quản lý Tuyến xe</router-link>
      </li>

      <li class="breadcrumb-item">Thêm mới Tuyến xe</li>
    </ol>
     <FormValidate v-slot="{ invalid ,reset }">

    <form   @submit.prevent="onSubmit" @reset.prevent="reset" id="sendform">
      
      <div class="container">
        <ul class="nav nav-tabs" role="tablist"  >
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">

      

          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
               <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header">
                                        <strong>Thông tin   </strong>
                                        <small>Form</small>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                           
                                           
                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Mã tuyến xe ( <span  class="text-danger">*</span> ) </label>
                    <InputValidate name="Mã Tuyến xe" rules="required" v-slot="errors">
                      <input type="text" v-model="Form.code" name="code" class="form-control" >
                      <p class="text-danger">
                         {{errors[0]}}
                      </p>
                    </InputValidate>
                                                </div>

                                            </div>

                                          

                                            <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Tuyến xe  ( <span  class="text-danger">*</span> )  </label>
                    <InputValidate name=" Tuyến xe" rules="required" v-slot="errors">
                      <input type="text" v-model="Form.name" name="name" class="form-control"  >
                      <p class="text-danger">
                         {{errors[0]}}
                      </p>
                    </InputValidate>
                                                </div>

                                            </div>
                                            

                                          

                                                       
                                <div class="col-sm-10">
                       
                                  <div class="form-group" v-if="business!=null">
                                    <label for="name"
                                      > Doanh Nghiệp Vận Tải <span class="text-danger">*</span></label
                                    >
                                  

                                     <InputValidate name="Tỉnh/Thành phố" rules="required" v-slot="{ errors }">
                                      
                                        <select style="height: 200px;"  v-model="multichoice" multiple class="form-control" >
                                          
                                           <option v-for="(v,k) in business" :key="k" :value="v.id">{{v.name}}</option>
                                        </select>
                                       
                                        <span class='text text-danger'>{{ errors[0] }}</span>
                                      </InputValidate>
                                                         
                                      
                                  </div>
                             
                            </div>

                                             <div class="col-sm-10">
                       
                              <div class="form-group" v-if="location!=null">
                                <label for="name"
                                  >Tỉnh/Thành phố   <span class="text-danger">*</span></label
                                >
                              

                                 <InputValidate name="Tỉnh/Thành phố" rules="required" v-slot="{ errors }">
                                  
                                    <select name="codeLocationDes" v-model="Form.codeLocationDes" class="form-control" >
                                       <option  value=""> Tuỳ chọn </option>
                                       <option v-for="(v,k) in location" :key="k" :value="v.id">{{v.name}}</option>
                                    </select>
                                   
                                    <span class='text text-danger'>{{ errors[0] }}</span>
                                  </InputValidate>
                                                     
                                  
                              </div>
                             
                            </div>

                                             
                                            
                                             <div class="col-sm-5">

                                                <div class="form-group">
                                                    <label for="name">Cự ly(KM)</label>
         <input type="text" v-model="Form.km" name="km" class="form-control" >
                                                   
                                                </div>

                                            </div>

                                            <div class="col-sm-5">

                                                <div class="form-group">
                                                    <label for="name">Giờ khởi hành </label>
         <input type="text" v-model="Form.timer" name="timer" class="form-control"  >
                                                   
                                                </div>

                                            </div>

                                             
                                             <div class="col-sm-5">

                                                <div class="form-group">
                                                    <label for="name"> Giá   </label>
         <input type="text" v-model="Form.price" name="price" class="form-control" >
                                                 
                                                </div>

                                            </div>


                                             <div class="col-sm-5">

                                                <div class="form-group">
                                                    <label for="name">  </label>
          <input type="text" v-model="Form.price1" name="price1"  class="form-control" >
                                                 
                                                </div>

                                            </div>

                                           

                           <div class="col-sm-10">

                                                <div class="form-group">
                                                    <label for="name"> Lộ trình    </label>
          <input type="text" v-model="Form.route" name="route" class="form-control" >
                                                  
                                                </div>

                        </div>
                                    
                                     



                                            
                                       
                                    </div>
                                </div>

               
                            </div>

                         
                            
                
                  
             </div>


            </div>
          </div>
          

          <div id="menu1" class="container tab-pane ">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>English  </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                     


                   

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >  Name </label
                          >                          
                            <InputValidate name="Tuyến xe" rules="required" v-slot="{ errors }">
                              <input v-model="Form.name_EN" type="text" name="name_en"  class="form-control" />
                                <span class='text text-danger'>{{ errors[0] }}</span>
                            </InputValidate>
                        </div>
                      </div>






                       




                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
          <p class="text text-info" v-if="invalid"> Vui lòng nhập đầy đủ dữ liệu </p>
            <button
              type="submit"
              class="btn btn-sm btn-primary"
             
              
            >
              <i class="fad fa-save"></i> Save
            </button>
             <button
              type="reset"
              class="btn btn-sm btn-warning ml-3"
              @click="myreset"
               :disabled="invalid"
            >
              <i class="fad fa-save"></i> Reset
            </button>
            
             
    
           
          </div>
        </div>
      </div>
    </form>
  </FormValidate>
  </div>
  <div v-else>
        <MyLoadTheme>
             
                <MyLoading v-for="k in 10" :key="k"></MyLoading>
           
        </MyLoadTheme>
  </div>
</template>

<script>
import axios from "axios";

  
export default {
  data() {
    return {
      is_load: false,
      img_one: "",
     
     
      list_cate: null,
      isLogin: null,
      Form: {
          id:"",
          code:'',
          name:"",
          km:"",
          timer:"",
          price:"",
          price1:"",
          route:"",
          name_EN:"",

          codeLocationDes:"",
          multichoice:""
      },
      multichoice:[],
    
      location:null,
      business:null

      
     
    };
  },
  created() {
    let isLogin = this.$session.get("isLogin");
    this.isLogin = isLogin.permistions.split(",");
    
  },
  
  mounted() {
    this.is_load=true;
        let p1=  axios.get(window.my_api + "api/location/get-locations", {
            headers: window.my_header
          })
          .then((res) => {
            if (res.status == 200) {
              this.location  = res.data.data;
                
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });


          let p2= axios.get(window.my_api + "api/vehicle/get-vehicle-by-id", {
            params:{
                vehicleId:this.$route.params.id

            },
            headers: window.my_header
          })
          .then((res) => {
            if (res.status == 200) {
              this.Form  = res.data.data;
              if(res.data.data.multichoice!=null){
                  this.multichoice= res.data.data.multichoice.split(",");
              }
              
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });


          let p3= axios.get(window.my_api + "api/business/get-businesss", {
           
              headers: window.my_header
          })
          .then((res) => {
            if (res.status == 200) {
             
               this.business  = res.data.data;
              
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });
          Promise.all([p1,p2,p3]).then( () =>{
              this.is_load=false;
          });

  },
  methods: {
    
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
    myreset(){
        
                 Object.keys(this.Form).forEach(v => {
                      this.Form[v]="";
                  });
    },
    onSubmit: function () {
        let myForm = document.getElementById("sendform");

       let formData = new FormData(myForm);
        
       
        formData.append("id",this.Form.id);
         formData.append("code_en",formData.get('code'));
 
         formData.append("km_en",formData.get('km'));
         formData.append("price_en",formData.get('price'));
         formData.append("price1_en",formData.get('price1'));
         formData.append("timer_en",formData.get('timer'));
         formData.append("route_en",formData.get('route'));
        

        formData.append("multichoice",this.multichoice.join(",")); 
           

       
        
        axios
          .post(window.my_api + "api/vehicle/create-or-update-vehicle", formData, {
            headers: window.my_header,
          })
          .then((res) => {

            if (res.status == 200) {
               if(res.data.successed){
                    this.$swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Cập nhật thành công.",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                      
                      
               }else{
                      this.$swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Vui lòng kiểm tra lại dữ liệu.",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                      
                     
               }
            

            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
             
            }
             this.is_load = false;
          });
      
    },
  },
};
</script>